<template>
  <NormalTemplate>
    <template #title>北斗智能ROS终端配套课程</template>
    <template #stepper><stepper
      :step-title="stepTitle"
      :in-step="inStep"
      :steps-name="stepsName"
      @stepChange="stepChange"
    /></template>
    <template #mainTitle>
      <v-toolbar-title class="light-blue--text text--darken-4 font-weight-black text-md-h5">
        <span>移动抓取</span>
      </v-toolbar-title>
      <v-spacer />
      <v-btn :disabled="inStep === 1" rounded class="mr-2 " @click="previousStep">上一步</v-btn>
      <v-btn :disabled="inStep === stepsName.length" rounded @click="nextStep">下一步</v-btn>
    </template>
    <template #main>
      <step-card v-if="inStep === 1">
        <template #title>
          在本章节需要用到
        </template>
        <template #introductionText>
          <p>一个终端（ubuntu系统版本18.04 + ROS melodic版本）</p>
          <p>一个waffle机器人</p>
          <p />
          <p>注意事项：小球必须在摄像头视野之内，底盘有三条线可以外接，一条是网线（舵机线），一条是激光雷达USB线，一条是摄像头USB线。网线一端接终端的TTL口，另一端接底盘和机械臂的控制线，要在开机之前插好，不然带电插会损害缓冲器。激光雷达线可以不接，摄像头USB线接终端USB2.0口。
          </p>
        </template>
      </step-card>
      <step-card v-if="inStep === 2">
        <template #title>
          设置摄像头参数
        </template>
        <template #introductionText>
          <p>正式抓取之前需要测量摄像头参数，点击“测量参数”按钮：</p><p />
          <p>摄像头镜片中心距离地面的高度（单位mm）,填入第一个文本框</p>
          <p>测量光心在地面投影与摄像头镜片中心点在地面投影之间的距离（单位mm）</p>
          <p>测量好之后，将参数填写在对应的文本框中，然后点击“设置参数”按钮</p>
          <p class="red--text">注意:测量好之后，必须关闭测量距离时打开的终端窗口</p>
          <p class="red--text">注意:测量步骤请严格按照操作手册进行</p>
          <v-form
            ref="paraForm"
            v-model="paraValid"
            lazy-validation
          >
            <v-text-field
              v-model="height"
              :counter="40"
              :rules="textRules"
              label="请输入高度"
              required
            />
            <v-text-field
              v-model="distance"
              :rules="textRules"
              :counter="40"
              label="请输入距离"
              required
            />
            <v-btn
              color="success"
              class="mr-4"
              :href="armcar_measure"
            >
              测量距离
            </v-btn>
            <v-btn
              :disabled="!paraValid"
              color="primary"
              class="mr-4"
              @click="validatePara"
            >
              设置参数
            </v-btn>
          </v-form>
        </template>
      </step-card>
      <step-card v-if="inStep === 3">
        <template #title>
          启动底盘
        </template>
        <template #btn>
          <v-btn color="primary" :href="armcar_bringup_core">启动底盘</v-btn>
        </template>
      </step-card>
      <step-card v-if="inStep === 4">
        <template #title>
          启动机械臂
        </template>
        <template #btn>
          <v-btn color="primary" :href="armcar_bringup_manipulation">启动机械臂</v-btn>
        </template>
      </step-card>
      <step-card v-if="inStep === 5">
        <template #title>
          启动moveit
        </template>
        <template #btn>
          <v-btn color="primary" :href="armcar_bringup_moveit">启动moveit</v-btn>
        </template>
      </step-card>
      <step-card v-if="inStep === 6">
        <template #title>
          启动主题的注册和管理
        </template>
        <template #btn>
          <v-btn color="primary" :href="armcar_manager">启动主题的注册和管理</v-btn>
        </template>
      </step-card>
      <step-card v-if="inStep === 7">
        <template #title>
          启动摄像头
        </template>
        <template #btn>
          <v-btn color="primary" :href="armcar_eye">启动摄像头</v-btn>
        </template>
      </step-card>
      <step-card v-if="inStep === 8">
        <template #title>
          注册底盘
        </template>
        <template #btn>
          <v-btn color="primary" :href="armcar_car">注册底盘</v-btn>
        </template>
      </step-card>
      <step-card v-if="inStep === 9">
        <template #title>
          注册机械臂节点
        </template>
        <template #btn>
          <v-btn color="primary" :href="armcar_arm">注册机械臂节点</v-btn>
        </template>
      </step-card>
      <step-card v-if="inStep === 10">
        <template #title>
          开始抓取
        </template>
        <template #introductionText>
          <p>小球不能离着摄像头太近，即在盲区里，不然会抓不到。抓球成功率达不到100%。</p>
          <p>为了避免看不到小球而转圈，可以关闭car节点</p>
          <p>如果机械臂够不到，会输出move，如果在视野内没找到小球，会输出circle</p>
          <p>如果光线效果很差，需要关闭eye节点，然后修改eye.py中的参数isshow，将其设置为True，再启动eye节点进行识别调试，参数改变后会即时生效，机械臂识别到就会进行抓取。</p>
          <p>机械臂开始抓小球，抓到小球后会把小球放在背后的篓子里。如果没有看到小球，会一直向左旋转直到看到抓取目标</p>
        </template>
        <template #btn>
          <v-btn color="primary" :href="armcar_wisdom">开始抓取</v-btn>
        </template>
      </step-card>
    </template>
  </NormalTemplate>
</template>

<script>
import NormalTemplate from 'components/normaltemplate'
import stepper from 'components/stepper'
import StepCard from 'components/stepCard'
import { Base64 } from 'js-base64'
export default {
  components: { StepCard, NormalTemplate, stepper },
  data: () => ({
    height: '',
    distance: '',
    textRules: [
      v => !!v || '内容不能为空',
      v => (v && v.length <= 40) || '账号必须小于40个字符'
    ],
    WifiShow: false,
    paraValid: true,
    e1: 1,
    stepTitle: '移动抓取',
    inStep: 1,
    armcar_measure: 'sbt://' + Base64.encode('armcar_measure'),
    armcar_bringup_core: 'sbt://' + Base64.encode('armcar_bringup_core'),
    armcar_bringup_manipulation: 'sbt://' + Base64.encode('armcar_bringup_manipulation'),
    armcar_bringup_moveit: 'sbt://' + Base64.encode('armcar_bringup_moveit'),
    armcar_manager: 'sbt://' + Base64.encode('armcar_manager'),
    armcar_eye: 'sbt://' + Base64.encode('armcar_eye'),
    armcar_car: 'sbt://' + Base64.encode('armcar_car'),
    armcar_arm: 'sbt://' + Base64.encode('armcar_arm'),
    armcar_wisdom: 'sbt://' + Base64.encode('armcar_wisdom'),
    stepsName: ['准备阶段', '设置摄像头参数', '启动底盘', '启动机械臂', '启动moveit', '启动主题的注册和管理', '启动摄像头', '注册底盘', '注册机械臂', '开始抓取']
  }),
  methods: {
    stepChange(val) {
      this.inStep = val
    },
    nextStep() {
      if (this.inStep < this.stepsName.length) {
        this.inStep++
      }
    },
    previousStep() {
      if (this.inStep > 1) {
        this.inStep--
      }
    },
    validatePara() {
      if (this.$refs.paraForm.validate()) {
        this.submitPara()
      }
    },
    submitPara() {
      const submitPara = Base64.encode('armcar_set_argument' + '%20' + this.height + '%20' + this.distance)
      console.log(submitPara)
      console.log(Base64.decode(submitPara))
      window.location.href = 'sbt://' + submitPara
    }

  }
}
</script>

<template>
  <v-img max-height="500" max-width="600" class="mt-15 ml-15" src="@/assets/indeximage/robot.png" />
</template>

<script>
export default {
  name: 'IndexImage'
}
</script>

<style scoped>

</style>

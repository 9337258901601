import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store/store'
// import Vuex from 'vuex'
import { getToken } from '@/utils/auth'
// import vuetifyToast from '@/components/toast/index'
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url  url = base url + request url
  withCredentials: true, // 跨域请求时发送cookies
  timeout: 5000 // request timeout
})

window.addEventListener('unhandledrejection', function(e) {
  // Event新增属性
  // @prop {Promise} promise - 状态为rejected的Promise实例
  // @prop {String|Object} reason - 异常信息或rejected的内容

  // 会阻止异常继续抛出，不让Uncaught(in promise) Error产生
  e.preventDefault()
})
//
// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = 'Bearer ' + getToken()
      // console.log(getToken())
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

  /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
  response => {
    const res = response
    // console.log(res.code)
    // if the custom code is not 20000, it is judged as an error.
    if (res.data.code !== 0) {
      // vuetifyToast.success(res.data.message || 'Error')
      // store.dispatch('snackbar/openSnackbar', {
      //   msg: `${res.data.message}`,
      //   color: 'error'
      // })
      // console.log('111222222221')
      Message({
        message: res.data.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      // 50008: 不合法 token; 50012: 其他客户端登录; 50014: Token 过期;
      if (res.data.code === 1000) {
        MessageBox.confirm('您在此页面停留的时间过长，您已退出，您可以取消停留在此页面，或重新登录', '您已登出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        })
      }
      console.log(res.data.message)
      return Promise.reject(new Error(res.data.message || 'Error'))
    } else {
      // console.log(res)
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    // vuetifyToast.success(error.data.message)
    Message({
      message: error.data.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service

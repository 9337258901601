const state = () => ({
  hostIP: '',
  slaveIP: ''
})
const mutations = {
  sethostIP(state, ip) {
    state.hostIP = ip
  },

  setslaveIP(state, ip) {
    state.slaveIP = ip
  }
}
export default {
  namespaced: true,
  state,
  mutations
}

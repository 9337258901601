<template>
  <v-row justify="space-around" class="mt-2">
    <v-card width="80%">
      <v-card-title>
        <div class="title font-weight-black h2 pl-4" style="border-left:5px solid #0070c0">
          <slot name="title" />
        </div>
      </v-card-title>
      <v-card-text>
        <div class="ml-8 mb-2">
          <p class="text-left font-weight-bold">
            <slot name="introductionText" />
          </p>
        </div>

      </v-card-text>
      <v-card-actions class="pr-15"><v-spacer /> <slot name="btn" /></v-card-actions>
    </v-card>
  </v-row>
</template>

<script>
export default {
  name: 'StepCard'
}
</script>

<style scoped>

</style>

<template>
  <index-template :course-list="courseList" margin-top="mt-10" />
</template>

<script>
import indexTemplate from 'components/indexTemplate'

export default {
  name: 'ControlClass',
  components: { indexTemplate },
  data: () => {
    return {
      courseList: [
        { name: '', text: '差速轮底盘控制', icon: 'mdi-hammer-wrench' },
        { name: '', text: '麦克拉姆轮底盘控制', icon: 'mdi-map-marker-outline' },
        { name: '', text: '伺服电机与视觉伺服', icon: 'mdi-arrow-decision' },
        { name: '', text: '视觉测量', icon: 'mdi-layers-search-outline' },
        { name: '', text: '机械臂控制规划—正规化', icon: 'mdi-navigation-outline' },
        { name: '', text: '机械臂控制规划—逆规化', icon: 'mdi-navigation-outline' },
        { name: '', text: '快速移动目标的跟踪预测', icon: 'mdi-navigation-outline' },
        { name: '', text: '模拟赛道上的自动驾驶', icon: 'mdi-navigation-outline' },
        { name: '', text: '四足机器人运动控制', icon: 'mdi-navigation-outline' }
      ]
    }
  }
}
</script>

<style scoped>

</style>

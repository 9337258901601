import Vuex from 'vuex'
import Vue from 'vue'
import getters from './getters'
// import ipConfig from '@/store/modules/ipconfig'
// import user from '@/store/modules/user'
// import user from '@/store/modules/user'
const modulesFiles = require.context('./modules', true, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})
Vue.use(Vuex)
const store = new Vuex.Store({
  // modules: {
  //   ipConfig,
  //   user,
  //   user
  // },
  modules,
  getters
})
export default store

<template>
  <v-app>
    <v-main>
      <v-container class="fill-height align-center justify-center">

        <v-card
          elevation="0"
          style="margin: 60px"
          max-width="400px"
        >
          <v-img
            class="white--text align-end"
            height="400px"
            contain
            src="@/assets/公司logo/book.jpg"
          />
          <v-card-subtitle class="pb-0" />

        </v-card>
        <v-card
          elevation="0"
          style="margin: 60px"
        >
          <v-card-title>用户注册</v-card-title>
          <v-form
            ref="form"
            v-model="valid"
            style="margin: 5px"
            lazy-validation
            class="v_form"
          >
            <v-text-field
              v-model="username"
              outlined

              :rules="nameRules"
              label="请输入用户名"
              required
            />

            <v-text-field
              v-model="password"
              type="password"
              outlined
              :rules="passwordRules"
              label="请输入密码"

              required
            />
            <v-text-field
              v-model="captchaCode"
              outlined
              :rules="captchaCodeRules"
              label="请输入验证码"
              required
            >
              <template #append>
                <v-img :src="svg" title="刷新验证码" style="cursor: pointer;margin-top:  -15px;" @click="refresh">
                  <template #placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      />
                    </v-row>
                  </template>
                </v-img>
              </template>
            </v-text-field>
            <v-btn
              :disabled="!valid"
              :loading="loading"
              color="success"
              class="mr-4"
              @click="validate"
            >
              注册
            </v-btn>
            <v-btn
              color="error"
              class="mr-4"
              @click="reset"
            >
              重新输入
            </v-btn>
          </v-form>
        </v-card>
      </v-container>
    </v-main>
  </v-app>

</template>

<script>
import { register } from '@/api/user'

export default {
  name: 'Register',
  data: () => ({
    svg: `${process.env.VUE_APP_BASE_API}/api/user/captcha`,
    loading: false,
    valid: true,
    username: '',
    nameRules: [
      v => !!v || '用户名不能为空',
      v => (v && v.length <= 18) || '用户名不能超过18个字符'
    ],
    password: '',
    passwordRules: [
      v => !!v || '密码不能为空',
      v => (v && v.length >= 8 && v.length <= 18) || '密码必须大于8位不能超过18个字符'
    ],
    captchaCode: '',
    captchaCodeRules: [
      v => !!v || '验证码不能为空'
    ],
    remember: false
  }),
  methods: {
    /**
     * 登录方法
     * @returns {Promise<void>}
     */
    validate() {
      // console.log(process.env.VUE_APP_BASE_API)
      const valid = this.$refs.form.validate()
      if (!valid) return
      this.loading = true
      register({ username: this.username, password: this.password })
        .then(() => {
          this.$store.dispatch('user/resetToken')
            .then(() => {
              this.$messagebox.alert('注册成功请重新登录', '注册成功', {
                confirmButtonText: '确定'
              })
                .then(() => {
                  this.loading = false
                  this.$router.push({ path: '/index' })
                })
            }
            )
          // 登录成功之后重定向到首页
        }).catch(err => {
          console.log('1111')
          this.loading = false
          console.log(err) // 登录失败提示错误
        })
      // if (this.username === 'saibotan' && this.password === '123456') {
      //   const flag = true
      //
      //   this.$store.commit('user/user', flag)
      //   await this.$router.push('/')
      // }
    },
    reset() {
      this.$refs.form.reset()
    },
    exit() {
      const flag = false
      this.$store.commit('login/login', flag)
      this.$router.push('/user')
      console.log('退出登录')
    },
    refresh() {
      this.svg = `${process.env.VUE_APP_BASE_API}/api/user/captcha?t=` + +Math.random() * 1000
      // captcha().then(response => {
      //   console.log(response)
      //   // this.svg = res.data
      // }).catch(error => {
      //   console.log(error)
      // })
    }
  }

}

</script>

<style scoped>

</style>

<template>
  <index-template :course-list="courseList" margin-top="mt-10" />
</template>
<script>
import indexTemplate from '@/components/indexTemplate'
export default {
  name: 'LocationClass',
  components: { indexTemplate },
  data: () => {
    return {
      courseList: [
        { name: '', text: '广域实时亚米级卫星定位', icon: 'mdi-hammer-wrench' },
        { name: '', text: 'IMU惯性传感器航迹推算', icon: 'mdi-map-marker-outline' },
        { name: '', text: 'GNSS+INS组合导航定位', icon: 'mdi-arrow-decision' },
        { name: '', text: '视觉里程计', icon: 'mdi-layers-search-outline' },
        { name: '', text: '视觉+INS组合里程计', icon: 'mdi-navigation-outline' },
        { name: '', text: 'GNSS+视觉+INS多源协同精密定位', icon: 'mdi-navigation-outline' },
        { name: '', text: '基于深度学习的机器人状态估计', icon: 'mdi-navigation-outline' }
      ]
    }
  }
}
</script>
<style scoped>

</style>

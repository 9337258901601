import Vue from 'vue'
import VueRouter from 'vue-router'
import freeclass from '@/router/modules/freeClass'
import notFound from 'views/404'
import freeClass from 'views/freeClass/index'
import indexImage from 'views/index/indexImage'
import index from 'views/index/index'
import locationClass from 'views/locationClass/index'
import buildAMapCLass from 'views/buildAMapClass/buildAMapCLass'
import controlClass from 'views/controlClass/controlClass'
import login from 'views/user/login'
import changePassword from 'views/user/changePassword'
import register from 'views/user/register'
Vue.use(VueRouter)
const indexRoutes = [
  {
    path: '/',
    name: '',
    component: index,
    redirect: '/index',
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      roles: ['admin', 'teacher', 'student']
    },
    children: [
      {
        path: 'index',
        component: indexImage,
        meta: {
          requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
          roles: ['admin', 'teacher', 'student']
        }
      }, {
        path: 'freeClass',
        component: freeClass,
        meta: {
          requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的,
          roles: ['admin', 'teacher', 'student']
        }
      }, {
        path: 'locationClass',
        component: locationClass,
        meta: {
          requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的,
          roles: ['admin', 'teacher', 'student']
        }
      },
      {
        path: 'buildAMapCLass',
        component: buildAMapCLass,
        meta: {
          requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的,
          roles: ['admin', 'teacher', 'student']
        }
      },
      {
        path: 'controlClass',
        component: controlClass,
        meta: {
          requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
          roles: ['admin', 'teacher', 'student']
        }
      }]
  }
]
const normalRoutes = [
  {
    path: '/user',
    name: 'login',
    component: login
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: changePassword,
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的,
      roles: ['admin', 'teacher', 'student']
    }
  },
  {
    path: '/register',
    name: 'register',
    component: register,
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的,
      roles: ['admin', 'teacher', 'student']
    }
  },
  {
    path: '/404',
    name: '404',
    component: notFound
  },
  {
    path: '*', // 此处需特别注意置于最底部
    redirect: '/404'
  }
]
const allRouters = indexRoutes.concat(freeclass.routes, normalRoutes)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: allRouters

})

export default router

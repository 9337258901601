<template>
  <v-app>
    <v-main>
      <v-container class="fill-height align-center justify-center">
        <v-img
            class="white--text align-center justify-center"
            height="100px"
            contain
            src="@/assets/公司logo/lab.png"
        />
        <v-card
          elevation="0"
          style="margin: 60px"
          max-width="400px"
        >
          <v-img
            class="white--text align-end"
            height="400px"
            contain
            src="@/assets/公司logo/book.jpg"
          />
          <v-card-subtitle class="pb-0" />

        </v-card>
        <v-card
          elevation="0"
          style="margin: 60px"
        >
          <v-card-title>用户登录</v-card-title>
          <v-form
            ref="form"
            v-model="valid"
            style="margin: 5px"
            lazy-validation
            class="v_form"
          >
            <v-text-field
              v-model="username"
              outlined

              :rules="nameRules"
              label="用户名"
              required
            />

            <v-text-field
              v-model="password"
              type="password"
              outlined
              :rules="passwordRules"
              label="密码"

              required
            />
            <v-text-field
              v-model="captchaCode"
              outlined
              :rules="captchaCodeRules"
              label="验证码"
              required
            >
              <template #append>
                <v-img :src="svg" title="刷新验证码" style="cursor: pointer;margin-top:  -15px;" @click="refresh">
                  <template #placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      />
                    </v-row>
                  </template>
                </v-img>
              </template>
            </v-text-field>
            <v-btn
              :disabled="!valid"
              :loading="loading"
              color="success"
              class="mr-4"
              @click="validate"
            >
              登录
              <template #loader>
                <span>正在登录</span>
              </template>
            </v-btn>
            <v-btn
              color="error"
              class="mr-4"
              to="/register"
            >
              用户注册
            </v-btn>
            <v-btn
              color="error"
              class="mr-4"

              to="/changePassword"
            >
              修改密码
            </v-btn>

          </v-form>
        </v-card>
        <v-footer style="width: 100%">
          <v-col
              class="text-center"
              cols="12"
          >
            本网站用于刊登《机器人自主智能导航》图书的勘误信息及工程实践的修订、增补，将陆续更新，欢迎各位读者访问
            <br>
            联系方式： zhiyu@zhiyuteam.com
          </v-col>
        </v-footer>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import { captcha } from '@/api/user'
// import vuetifyToast from '@/components/toast/index'

export default {
  name: 'Login',
  data: () => ({
    svg: `${process.env.VUE_APP_BASE_API}/api/user/captcha`,
    loading: false,
    valid: true,
    username: '',
    nameRules: [
      v => !!v || '用户名不能为空',
      v => (v && v.length <= 18) || '用户名不能超过18个字符'
    ],
    password: '',
    passwordRules: [
      v => !!v || '密码不能为空',
      v => (v && v.length <= 18) || '密码不能超过18个字符'
    ],
    captchaCode: '',
    captchaCodeRules: [
      v => !!v || '验证码不能为空'
    ],
    remember: false
  }),
  methods: {
    /**
     * 登录方法
     * @returns {Promise<void>}
     */
    validate() {
      // console.log(process.env.VUE_APP_BASE_API)
      const valid = this.$refs.form.validate()
      // console.log(this.username, this.password)
      // console.log(this.$store)
      if (!valid) return
      this.loading = true
      // user(this.username, this.password).then(response => {
      //   console.log(response.data.message)
      //   const flag = true
      //   this.loading = false
      //   this.$store.commit('user/user', flag)
      //   this.$router.push('/')
      // })
      this.$store.dispatch('user/login', { username: this.username, password: this.password, captcha: this.captchaCode })
        .then(() => {
          this.loading = false
          this.$router.push({ path: '/index' }) // 登录成功之后重定向到首页
        }).catch(err => {
          console.log('1111')
          this.loading = false
          console.log(err) // 登录失败提示错误
        })
      // if (this.username === 'saibotan' && this.password === '123456') {
      //   const flag = true
      //
      //   this.$store.commit('user/user', flag)
      //   await this.$router.push('/')
      // }
    },
    reset() {
      // console.log(vuetifyToast.success('aaaa'))
      // this.$refs.form.reset()
      // this.$store.dispatch('snackbar/openSnackbar', {
      //   msg: 'hello',
      //   color: 'success'
      // })
      // this.$message('这是一条消息提示')
      // this.$messagebox.alert('这是一段内容', '标题名称', {
      //   confirmButtonText: '确定'
      // })
      // console.log(this.$store)
      this.$router.push({ path: '/changePassword' })
      console.log(this.$router)
    },
    exit() {
      const flag = false
      this.$store.commit('login/login', flag)
      this.$router.push('/user')
      console.log('退出登录')
    },
    refresh() {
      this.svg = `${process.env.VUE_APP_BASE_API}/api/user/captcha?t=` + +Math.random() * 1000
      // captcha().then(response => {
      //   console.log(response)
      //   // this.svg = res.data
      // }).catch(error => {
      //   console.log(error)
      // })
    }
  }
}
</script>

<style scoped>
.v_form {
  min-width: 380px;
}
</style>

<template>

  <v-row justify="center" class="ma-0">
    <v-col cols="6" align-self="center">
      <v-list rounded height="0" width="330">
        <v-list-item-group
          color="primary"
        >
          <v-list-item
            v-for=" (value ,index) in courseList.slice(0,5)"
            :key="index"
            :to="value.name"
            class="elevation-6"
            :class="marginTop"
          >
            <v-list-item-icon>
              <v-icon v-text="value.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="value.text" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-col cols="6" align-self="center">
      <v-list rounded height="10" width="330">
        <v-list-item-group
          color="primary"
        >
          <v-list-item
            v-for=" (value ,index) in courseList.slice(6)"
            :key="index"
            :to="value.name"
            class="elevation-6"
            :class="marginTop"
          >
            <v-list-item-icon>
              <v-icon v-text="value.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="value.text" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'IndexTemplate',
  props: {
    courseList: {
      type: Array,
      default: () => {
        return [{ name: '', text: '', icon: '' }]
      }
    },
    marginTop: {
      type: String,
      default: 'mt-10'
    }
  }
}
</script>

<style scoped>

</style>

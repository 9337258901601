import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/api/user/login',
    method: 'post',
    data: data
  })
}
export function getInfo() {
  return request({
    url: '/api/user/info',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: '/api/user/logout',
    method: 'post'
  })
}
export function captcha() {
  return request({
    url: '/api/user/captcha',
    method: 'get'
  })
}
export function register(data) {
  return request({
    url: '/api/user/register',
    method: 'post',
    data: data
  })
}
export function changePassword(data) {
  return request({
    url: '/api/user/updateuser',
    method: 'post',
    data: data
  })
}

<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data: () => ({
    //
  })
}
</script>
<style>
html { overflow-y: auto!important; }
::-webkit-scrollbar {
  width: 10px;
  /*滚动条宽度*/
  height: 5px;
  /*滚动条高度*/
  background-color: #e6eaee;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 14px;
  -webkit-box-shadow: inset 0 0 6px rgba(159, 149, 149, 0.8);
  background-color: #b3b3b9;
  /*滚动条的背景颜色*/
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: none;
}
</style>

<template>
  <NormalTemplate>
    <template #title>北斗智能ROS终端配套课程</template>
    <template #stepper><stepper
      :step-title="stepTitle"
      :in-step="inStep"
      :steps-name="stepsName"
      @stepChange="stepChange"
    /></template>
    <template #mainTitle>
      <v-toolbar-title class="light-blue--text text--darken-4 font-weight-black text-md-h5">
        <span>{{ stepTitle }}</span>
      </v-toolbar-title>
      <v-spacer />
      <v-btn :disabled="inStep === 1" rounded class="mr-2 " @click="previousStep">上一步</v-btn>
      <v-btn :disabled="inStep === stepsName.length" rounded @click="nextStep">下一步</v-btn>
    </template>
    <template #main>
      <step-card v-if="inStep === 1">
        <template #title>
          在本章节需要用到

        </template>
        <template #introductionText>
          <p>一个终端（ubuntu系统版本18.04 + ROS melodic版本）</p>
          <p>一台个人电脑（ubuntu系统版本18.04 + ROS melodic版本）</p>
          <p>一个waffle机器人</p>
          <p>接线：连接舵机线和雷达数据线。</p>
          <p>本章节的操作分为两部分，主机（PC机）和从机（终端），请注意区分</p>
        </template>
      </step-card>
      <step-card v-if="inStep === 2">
        <template #title>
          在本章节需要用到
        </template>
        <template #introductionText>
          <p>我们将PC机作为主机，终端作为从机。</p>
          <p>本机IP地址就是你现在操作的这台机器的IP地址。</p>
          <p>点击<span class="success--text">查询IP</span>，可以查询当前使用的机器的IP</p>
          <p>主机和从机上都填写完IP地址后，点击<span class="success--text">配置IP</span>。</p>
          <p>在PC机上执行roscore，点击<span class="success--text">主机运行ROSCORE</span></p>
          <p>然后在终端上点击<span class="success--text">测试通信</span>，如果可以看到两个topic，说明通信成功</p>
          <v-form ref="ipForm" v-model="valid">
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="localIP"
                    label="请输入主机IP地址"
                    :rules="[rules.required, rules.ipAddress]"
                    outlined
                    dense
                  />

                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="slaveIP"
                    label="请输从机IP地址"
                    :rules="[rules.required, rules.ipAddress]"
                    outlined
                    dense
                  />
                </v-col>

              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-btn color="secondary" class="ma-2" :href="getIP">查询ip</v-btn>
                  <v-btn :disabled="!valid" color="primary" class="ma-2" @click="validateIP">配置IP</v-btn>
                  <v-btn color="primary" :href="slam_roscore" class="ma-2">主机运行roscore</v-btn>
                  <v-btn :disabled="!valid" color="success" class="ma-2" @click="validateTest">从机测试通信</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template #btn />
      </step-card>
      <step-card v-show="inStep === 3">
        <template #title>
          安装依赖
        </template>
        <template #introductionText>
          在主机和从机上都要执行本操作
        </template>
        <template #btn>
          <v-btn color="primary" :href="slamInstall">安装依赖
          </v-btn>
        </template>
      </step-card>
      <step-card v-show="inStep === 4">
        <template #title>
          添加权限
        </template>
        <template #introductionText>
          从机上要执行本操作
        </template>
        <template #btn>
          <v-btn color="primary" :href="slam_permission">添加权限
          </v-btn>
        </template>
      </step-card>
      <step-card v-show="inStep === 5">
        <template #title>
          运行主节点
        </template>
        <template #introductionText>
          主机上要执行本操作
        </template>
        <template #btn>
          <v-btn color="primary" :href="slam_roscore">运行主节点
          </v-btn>
        </template>
      </step-card>
      <step-card v-show="inStep === 6">
        <template #title>
          建图：启动底盘
        </template>
        <template #introductionText>
          本操作在从机（终端）上执行
        </template>
        <template #btn>
          <v-btn color="primary" @click="slam_robot">启动底盘
          </v-btn>
        </template>
      </step-card>
      <step-card v-show="inStep === 7">
        <template #title>
          建图：开启键盘控制
        </template>
        <template #introductionText>
          本操作在主机（PC机）上执行
        </template>
        <template #btn>
          <v-btn color="primary" @click="slamKeyboard">开启键盘控制
          </v-btn>
        </template>
      </step-card>
      <step-card v-show="inStep === 8">
        <template #title>
          建图：开启gmapping建图
        </template>
        <template #introductionText>
          本操作在主机（PC机）上执行
        </template>
        <template #btn>
          <v-btn color="primary" @click="slam_gmapping">开启gmapping建图
          </v-btn>
        </template>
      </step-card>
      <step-card v-show="inStep === 9">
        <template #title>
          建图：保存地图
        </template>
        <template #introductionText>
          本操作在主机（PC机）上执行，地图默认保存在~/map文件夹下
          <v-text-field
            v-model="mapName"
            :counter="10"
            label="请输入保存的地图名称"
            required
          />
        </template>
        <template #btn>
          <v-btn color="primary" @click="slam_save_map">保存地图
          </v-btn>
        </template>
      </step-card>
      <step-card v-show="inStep === 10">
        <template #title>
          导航规划：导入地图
        </template>
        <template #introductionText>
          本操作在主机（PC机）上执行
          <v-text-field
            v-model="mapInputName"
            :counter="10"
            label="请输入需要导入的地图名称"
            required
          />
        </template>
        <template #btn>
          <v-btn color="primary" @click="slam_import_map">导入地图
          </v-btn>
        </template>
      </step-card>
      <step-card v-show="inStep === 11">
        <template #title>
          导航规划：设定起点和终点
        </template>
        <template #introductionText>
          <p>本操作在主机（PC机）上执行</p>
          <p>执行将机器人放置合适的起点，并用 pos estimate 箭头将 rviz 的机器人模型放到对应的位置和朝向。在 rviz 上点击 nav goal，并在地图上指定机器人的最终位置和朝向，机器人就会开始规划路径，进行导航避障等行为
          </p>

        </template>
      </step-card>
    </template>
  </NormalTemplate>
</template>

<script>
import NormalTemplate from 'components/normaltemplate'
import stepper from 'components/stepper'
import StepCard from 'components/stepCard'
import { Base64 } from 'js-base64'
export default {
  components: { StepCard, NormalTemplate, stepper },
  data: () => ({
    valid: '',
    localIP: '',
    slaveIP: '',
    mapName: '',
    mapInputName: '',
    rules: {
      required: value => !!value || 'IP不能为空.',
      ipAddress: value => {
        const pattern = /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/
        return pattern.test(value) || '不合法的ip地址.'
      } },
    stepTitle: '机器人导航与规划',
    inStep: 1,
    getIP: 'sbt://' + Base64.encode('getIP'),
    setIP: 'sbt://',
    slam_test: 'sbt://' + Base64.encode('slam_test'),
    slamInstall: 'sbt://' + Base64.encode('slam_install'),
    slam_permission: 'sbt://' + Base64.encode('slam_permission'),
    slam_roscore: 'sbt://' + Base64.encode('slam_roscore'),
    // slam_robot: 'sbt://' + Base64.encode('slam_robot'),
    // slam_keyboard: 'sbt://' + Base64.encode('slam_keyboard'),
    // slam_gmapping: 'sbt://' + Base64.encode('slam_gmapping'),
    // slam_save_map: 'sbt://' + Base64.encode('slam_save_map'),
    slam_navi: 'sbt://' + Base64.encode('slam_navi'),
    stepsName: ['准备阶段', '网络配置', '安装依赖', '添加权限', '运行主节点', '建图：启动底盘', '建图：开启键盘控制', '建图：开启gmapping', '建图：保存地图', '导航规划：导入地图', '导航规划：设定起点和终点']
  }),
  watch: {
  },
  methods: {
    stepChange(val) {
      this.inStep = val
    },
    nextStep() {
      if (this.inStep < this.stepsName.length) {
        this.inStep++
      }
    },
    previousStep() {
      if (this.inStep > 1) {
        this.inStep--
      }
    },
    validateIP() {
      if (this.$refs.ipForm.validate()) {
        this.submitIP()
      }
    },
    submitIP() {
      const submitPara = Base64.encode('setIP' + '%20' + this.localIP + '%20' + this.slaveIP)
      console.log(submitPara)
      console.log(Base64.decode(submitPara))
      this.$store.commit('ipconfig/sethostIP', this.localIP)
      this.$store.commit('ipconfig/setslaveIP', this.slaveIP)
      console.log(this.$store.state.ipconfig.hostIP, this.$store.state.ipconfig.slaveIP)
      window.location.href = 'sbt://' + submitPara
    },
    validateTest() {
      if (this.$refs.ipForm.validate()) {
        this.slamTest()
      }
    },
    slamTest() {
      const slamTest = Base64.encode('slam_test' + '%20' + this.localIP + '%20' + this.slaveIP)
      console.log(slamTest)
      console.log(Base64.decode(slamTest))
      window.location.href = 'sbt://' + slamTest
    },
    slamKeyboard() {
      const slam_keyboard = Base64.encode('slam_keyboard' + '%20' + this.localIP + '%20' + this.slaveIP)
      window.location.href = 'sbt://' + slam_keyboard
    },
    slam_robot() {
      const slam_robot = Base64.encode('slam_robot' + '%20' + this.localIP + '%20' + this.slaveIP)
      window.location.href = 'sbt://' + slam_robot
    },
    slam_gmapping() {
      const slam_gmapping = Base64.encode('slam_gmapping' + '%20' + this.localIP + '%20' + this.slaveIP)
      window.location.href = 'sbt://' + slam_gmapping
    },
    slam_save_map() {
      const slam_save_map = Base64.encode('slam_save_map' + '%20' + this.mapName)
      window.location.href = 'sbt://' + slam_save_map
    },
    slam_import_map() {
      const slam_import_map = Base64.encode('slam_navi' + '%20' + this.mapInputName + '%20' +
          this.$store.state.ipconfig.hostIP + '%20' + this.$store.state.ipconfig.slaveIP)
      console.log(this.$store.state.ipconfig.hostIP, this.$store.state.ipconfig.slaveIP)
      console.log(slam_import_map)
      window.location.href = 'sbt://' + slam_import_map
    }
  }
}
</script>

<template>
  <indexTemplate :course-list="courseList" margin-top="mt-10" />
</template>
<script>
import indexTemplate from 'components/indexTemplate'
export default {
  name: 'FreeClass',
  components: { indexTemplate },
  data: () => {
    return {
      courseList: [
        { name: 'rosInstall', text: 'ROS安装', icon: 'mdi-hammer-wrench' },
        { name: 'gnss', text: 'GNSS高精度定位', icon: 'mdi-map-marker-outline' },
        { name: 'moveToGrab', text: '移动抓取', icon: 'mdi-arrow-decision' },
        { name: 'targetDetection', text: '目标检测', icon: 'mdi-layers-search-outline' },
        { name: 'robotNavigationPlanning', text: '机器人导航与规划', icon: 'mdi-navigation-outline' }
      ]
    }
  }
}
</script>
<style scoped>

</style>

const state = () => ({
  islogin: ''
})
const mutations = {
  login: (state, n) => {
    // 传入登录状态islogin
    const islogin = JSON.parse(n)
    sessionStorage.setItem('islogin', JSON.stringify(islogin))
    console.log(islogin)
    state.islogin = islogin
  }
}
export default {
  namespaced: true,
  state,
  mutations
}

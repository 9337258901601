<template>
  <v-app id="inspire">
    <v-navigation-drawer stateless value="1" app clipped class="light-blue darken-4">
      <slot name="stepper" />
    </v-navigation-drawer>
    <v-app-bar
      app
      color="#0070c0"
      flat
      dark
      clipped-left
      class="elevation-6"
    >
      <v-toolbar-title> <slot name="title" /> </v-toolbar-title>
      <v-spacer />
      <v-btn text color="error" :href="kill">关闭所有终端</v-btn>
      <v-btn text to="/">
        返回首页
      </v-btn>
    </v-app-bar>
    <v-main class="grey lighten-3">
      <v-toolbar class="elevation-0 grey lighten-3">
        <slot name="mainTitle" />
      </v-toolbar>
      <slot name="main" /> </v-main>
  </v-app>
</template>

<script>
import { Base64 } from 'js-base64'

export default {
  name: 'NormalTemplate',
  data: () => ({
    kill: 'sbt://' + Base64.encode('kill')
  })
}
</script>

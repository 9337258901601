<template>
  <NormalTemplate>
    <template #title>北斗智能ROS终端配套课程</template>
    <template #stepper><stepper
      :step-title="stepTitle"
      :in-step="inStep"
      :steps-name="stepsName"
      @stepChange="stepChange"
    /></template>
    <template #mainTitle>
      <v-toolbar-title class="light-blue--text text--darken-4 font-weight-black text-md-h5">
        <span>{{ stepTitle }}</span>
      </v-toolbar-title>
      <v-spacer />
      <v-btn :disabled="inStep === 1" rounded class="mr-2 " @click="previousStep">上一步</v-btn>
      <v-btn :disabled="inStep === stepsName.length" rounded @click="nextStep">下一步</v-btn>
    </template>
    <template #main>
      <step-card v-if="inStep === 1">
        <template #title>
          在本章节需要用到
        </template>
        <template #introductionText>
          <p>一个终端（ubuntu系统版本18.04 ）</p>
        </template>
      </step-card>
      <step-card v-if="inStep === 2">
        <template #title>
          编译安装
        </template>
        <template #btn>
          <v-btn color="primary" :href="yolo_make">编译安装</v-btn>
        </template>
      </step-card>
      <step-card v-show="inStep === 3">
        <template #title>
          检测图片
        </template>
        <template #introductionText>
          <v-form v-model="imageFormValid">
            <v-file-input
              v-model="pictureName"
              accept="image/*"
              label="选择图片"
              required
              :rules="[v => !!v || '必须选择一个文件']"
              outlined
              dense
            />
          </v-form>
          <v-btn :disabled="!imageFormValid" color="primary" @click="yolo_image1">检测图片</v-btn>
        </template>
      </step-card>
      <step-card v-show="inStep === 4">
        <template #title>
          检测视频
        </template>
        <template #introductionText>
          <v-form v-model="videoFormValid">
            <v-file-input
              v-model="videoName"
              accept="video/*"
              label="选择视频"
              required
              :rules="[v => !!v || '必须选择一个文件']"
              outlined
              dense
            />
            <v-btn :disabled="!videoFormValid" color="primary" @click="yolo_video1">检测视频</v-btn>
          </v-form>
        </template>
      </step-card>
      <step-card v-show="inStep === 5">
        <template #title>
          检测实时视频
        </template>
        <template #btn>
          <v-btn color="primary" :href="yolo_camera">检测实时视频</v-btn>
        </template>
      </step-card>
    </template>
  </NormalTemplate>
</template>

<script>
import NormalTemplate from 'components/normaltemplate'
import stepper from 'components/stepper'
import StepCard from 'components/stepCard'
import { Base64 } from 'js-base64'
export default {
  components: { StepCard, NormalTemplate, stepper },
  data: () => ({
    e1: 1,
    stepTitle: '目标检测',
    inStep: 1,
    imageFormValid: '',
    videoFormValid: '',
    pictureName: [],
    videoName: [],
    yolo_image: 'sbt://',
    yolo_video: 'sbt://',
    yolo_make: 'sbt://' + Base64.encode('yolo_make'),
    yolo_camera: 'sbt://' + Base64.encode('yolo_camera'),
    stepsName: ['准备阶段', '编译安装', '检测图片', '检测视频', '检测实时视频']
  }),
  watch: {
    pictureName(newName) {
      console.log(newName.name)
    },
    videoName(newName) {
      console.log(newName.name)
    }
  },
  methods: {
    yolo_image1() {
      if (this.imageFormValid) {
        window.location.href = this.yolo_image + Base64.encode(`yolo_image%20` + this.pictureName.name)
      }
      console.log(window.location.href)
    },
    yolo_video1() {
      if (this.videoFormValid) {
        window.location.href = this.yolo_video + Base64.encode(`yolo_video%20` + this.videoName.name)
        console.log(window.location.href)
      }
    },
    stepChange(val) {
      this.inStep = val
    },
    nextStep() {
      if (this.inStep < this.stepsName.length) {
        this.inStep++
      }
    },
    previousStep() {
      if (this.inStep > 1) {
        this.inStep--
      }
    }
  }
}
</script>

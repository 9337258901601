import router from '@/router'
import store from '@/store/store'
import { MessageBox } from 'element-ui' // 引入Message, MessageBox
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/user', '/changePassword', '/register'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()
  if (store.getters.token) {
    if (to.path === '/404') {
      // if is logged in, redirect to the home page
      console.log(3333)
      next()
      NProgress.done()
    }
  }
  if (store.getters.token) {
    if (to.path === '/user') {
      // if is logged in, redirect to the home page
      console.log(3333)
      next({ path: '/' })
      NProgress.done()
    } else {
      await store.dispatch('user/getInfo')
      const hasGetUserInfo = store.getters.roles
      // console.log(to.meta.roles)
      // console.log(isStrInArray('hasGetUserInfo', to.meta.roles))
      if (hasGetUserInfo.length !== 0) {
        console.log(to.meta)
        await store.dispatch('user/getInfo')
        if (to.meta.roles.includes(hasGetUserInfo)) {
          console.log(1111)
          next() // 放行
          NProgress.done()
        } else {
          // to.path = '/404'
          console.log(to.path)
          NProgress.done()
          MessageBox.alert('此课程需付费,有需要请联系杨老师:18062019250', '此课程需付费', {
            confirmButtonText: '确定'
          })
          next({ path: '/index' }) // 跳到404页面
        }
      } else {
        try {
          // get user info
          await store.dispatch('user/getInfo')
          // console.log(store.getters.roles)
          console.log('11111')
          next()
        } catch (error) {
          // remove token and go to user page to re-user
          console.log(error)
          await store.dispatch('user/resetToken')
          store.dispatch('snackbar/openSnackbar', {
            msg: `${error}`,
            color: 'error'
          })
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free user whitelist, go directly
      console.log(4545)
      next()
    } else {
      // other pages that do not have permission to access are redirected to the user page.
      console.log('to user')
      console.log(whiteList.indexOf(to.path), to.path)
      console.log(whiteList)
      next(`/user`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

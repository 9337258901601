<template>
  <v-stepper
    v-model="step"
    vertical
    class="light-blue darken-4"
    elevation="0"
    dark
    tile
  >
    <div style="text-align: center" class="mt-3 text-xl-h5">{{ stepTitle }} </div>

    <template v-for="n in steps">
      <v-stepper-step
        :key="n"
        :complete="step > n"
        :step="n"
        editable
        edit-icon="$complete"
      >
        {{ stepsName[n-1] }}
      </v-stepper-step>
      <v-stepper-content :key="`${n}-step`" :step="n" />
    </template>
  </v-stepper>
</template>

<script>
export default {
  name: 'Stepper',
  props: {
    stepTitle: {
      type: String,
      default: 'title' },
    inStep: {
      type: Number,
      default: 1
    },
    stepsName: {
      type: Array,
      default: function() {
        return ['1', '2', '3', '4', '5', '6', '7', '8', '9']
      }
    }
  },
  data: function() {
    return {
      step: this.inStep,
      steps: this.stepsName.length
    }
  },
  watch: {
    inStep(val) {
      this.step = val
    },
    step(val) {
      this.$emit('stepChange', val)
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <NormalTemplate>
    <template #title>北斗智能ROS终端配套课程</template>
    <template #stepper><stepper
      :step-title="stepTitle"
      :in-step="inStep"
      :steps-name="stepsName"
      @stepChange="stepChange"
    /></template>
    <template #mainTitle>
      <v-toolbar-title class="light-blue--text text--darken-4 font-weight-black text-md-h5">
        <span>ROS安装</span>
      </v-toolbar-title>
      <v-spacer />
      <v-btn :disabled="inStep === 1" rounded class="mr-2 " @click="previousStep">上一步</v-btn>
      <v-btn :disabled="inStep === stepsName.length" rounded @click="nextStep">下一步</v-btn>
    </template>
    <template #main>
      <step-card v-if="inStep === 1">
        <template #title>
          在本章节需要用到
        </template>
        <template #introductionText>
          <p>一个终端（ubuntu系统版本18.04 ）</p>
          <p>一台个人电脑（ubuntu系统版本18.04）</p>
          <p>我们安装的ROS版本为melodic</p>
        </template>
      </step-card>
      <step-card v-if="inStep === 2">
        <template #title>
          安装ROS
        </template>
        <template #introductionText>
          <p>在“安装ROS”阶段中，我们点击安装ROS按钮</p>
          <p>会弹出一个命令行终端，我们需要输入用户名对应的密码。</p>
        </template>
        <template #btn>
          <v-btn color="primary" :href="rosInstall">安装ROS</v-btn>
        </template>
      </step-card>
      <step-card v-if="inStep === 3">
        <template #title>
          初始化rosdep
        </template>
        <template #btn>
          <v-btn color="primary" :href="rosInit">初始化rosdep</v-btn>
        </template>
      </step-card>
      <step-card v-if="inStep === 4">
        <template #title>
          设置环境
        </template>
        <template #btn>
          <v-btn color="primary" :href="SettingEnvironment">设置环境</v-btn>
        </template>
      </step-card>
      <step-card v-if="inStep === 5">
        <template #title>
          测试ROS
        </template>
        <template #introductionText>
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step
                :complete="e1 > 1"
                step="1"
              >
                第一步
              </v-stepper-step>
              <v-divider />
              <v-stepper-step
                :complete="e1 > 2"
                step="2"
              >
                第二步
              </v-stepper-step>

              <v-divider />

              <v-stepper-step step="3">
                第三步
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card
                  elevation="0"
                  class="mb-12"
                  height="200px"
                >
                  <v-card-text>
                    <p class="text-left font-weight-bold">
                      点击"运行roscore"按钮，运行roscore
                    </p>

                  </v-card-text>
                  <v-card-actions class="pl-15">

                    <v-btn
                      color="success"
                      :href="testRos1"
                    >
                      运行roscore
                    </v-btn>
                  </v-card-actions>
                </v-card>

                <v-btn
                  color="primary"
                  @click="e1 = 2"
                >
                  下一步
                </v-btn>

                <v-btn text disabled @click="e1 = 1">
                  上一步
                </v-btn>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card
                  elevation="0"
                  class="mb-12"
                  height="200px"
                >
                  <v-card-text>
                    <p class="text-left font-weight-bold">
                      点击ros测试按钮，这时你会看见一只静止的小海龟
                    </p>
                  </v-card-text>
                  <v-card-actions class="pl-15">

                    <v-btn
                      color="success"
                      :href="testRos2"
                    >
                      ros测试
                    </v-btn>
                  </v-card-actions>
                </v-card>

                <v-btn
                  color="primary"
                  @click="e1 = 3"
                >
                  下一步
                </v-btn>

                <v-btn text @click="e1 = 1">
                  上一步
                </v-btn>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-card
                  elevation="0"
                  class="mb-12"
                  height="200px"
                >
                  <v-card-text>
                    <p class="text-left font-weight-bold">
                      点击“控制小海龟”按钮,这里你的鼠标要停留在当前这个终端界面，按键盘上面的上下左右来控制小海龟的移动
                    </p>
                  </v-card-text>
                  <v-card-actions class="pl-15">

                    <v-btn
                      color="success"
                      :href="testRos3"
                    >
                      控制小海龟
                    </v-btn>
                  </v-card-actions>
                </v-card>

                <v-btn
                  color="primary"
                  disabled
                  @click="e1 = 3"
                >
                  最后一步
                </v-btn>

                <v-btn text @click="e1 = 2">
                  上一步
                </v-btn>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </template>
      </step-card>
    </template>
  </NormalTemplate>
</template>

<script>
import NormalTemplate from 'components/normaltemplate'
import stepper from 'components/stepper'
import StepCard from 'components/stepCard'
import { Base64 } from 'js-base64'
export default {
  components: { StepCard, NormalTemplate, stepper },
  data: () => ({
    e1: 1,
    stepTitle: 'ROS安装',
    inStep: 1,
    rosInstall: 'sbt://' + Base64.encode('rosinstall'),
    rosInit: 'sbt://' + Base64.encode('rosinit'),
    SettingEnvironment: 'sbt://' + Base64.encode('rossetenv'),
    testRos1: 'sbt://' + Base64.encode('rostest1'),
    testRos2: 'sbt://' + Base64.encode('rostest2'),
    testRos3: 'sbt://' + Base64.encode('rostest3'),
    stepsName: ['准备阶段', '安装ROS', '初始化rosdep', '设置环境', '测试ROS']
  }),
  methods: {
    stepChange(val) {
      this.inStep = val
    },
    nextStep() {
      if (this.inStep < this.stepsName.length) {
        this.inStep++
      }
    },
    previousStep() {
      if (this.inStep > 1) {
        this.inStep--
      }
    }
  }
}
</script>

<template>
  <v-app>

    <h1 style="z-index: 999">融合北斗和AI的智能导航教学系统</h1>
    <v-row no-gutters>
      <v-col cols="6">
        <v-row justify="center" class="ma-0 mt-10 bord_left">
          <v-col lg="6" md="10" sm="12" align-self="center">
            <v-list rounded height="">
              <v-list-item-group
                active-class="blue darken-4 white--text"
                color="primary"
              >
                <v-list-item
                  v-for=" (value ,index) in courseList"
                  :key="index"
                  :to="value.name"
                  class="mt-14 elevation-6"
                >
                  <v-list-item-icon>
                    <v-icon v-text="value.icon" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="value.text" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <router-view />
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters class="mb-16">

      <v-img
        alt="logo"
        contain
        height="100"
        src="@/assets/公司logo/lab.png"
      /></v-row>
  </v-app>
</template>

<script>

export default {
  name: 'Index',
  data: () => {
    return {
      courseList: [
        { name: 'freeClass', text: '体验课程', icon: 'mdi-hammer-wrench' },
        { name: 'locationClass', text: '北斗高精度协同精密定位课程', icon: 'mdi-map-marker-outline' },
        { name: 'buildAMapCLass', text: '语义理解与建图课程', icon: 'mdi-arrow-decision' },
        { name: 'controlClass', text: '机器人手、脚、眼协同控制课程', icon: 'mdi-layers-search-outline' }
      ]
    }
  }

}
</script>

<style scoped>
h1 {
  margin: 50px 0 -20px;
  text-align: center;
  font-size: 60px;
  color: #0070c0;
  border-bottom: 1px solid #0070c0;
}
.bord_left {
  border-right: 2px dashed #c5c9ca ;
}
</style>

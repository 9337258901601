<template>
  <index-template :course-list="courseList" margin-top="mt-10" />
</template>

<script>
import indexTemplate from 'components/indexTemplate'
export default {
  name: 'BuildAMapCLass',
  components: { indexTemplate },
  data: () => {
    return {
      courseList: [
        { name: '', text: '机器视觉目标跟踪\n', icon: 'mdi-hammer-wrench' },
        { name: '', text: '机器视觉语义分割\n', icon: 'mdi-map-marker-outline' },
        { name: '', text: '激光2D SLAM \n', icon: 'mdi-arrow-decision' },
        { name: '', text: '激光3D SLAM \n', icon: 'mdi-layers-search-outline' },
        { name: '', text: '视觉SLAM\n', icon: 'mdi-navigation-outline' },
        { name: '', text: '融合机器视觉的语义SLAM\n', icon: 'mdi-navigation-outline' },
        { name: '', text: '机器人稠密语义地图构建\n', icon: 'mdi-navigation-outline' },
        { name: '', text: '全局导航规划', icon: 'mdi-navigation-outline' },
        { name: '', text: '基于深度强化学习的机器人导航', icon: 'mdi-navigation-outline' }
      ]
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <normal-template>
    <template #title>北斗智能ROS终端配套课程</template>
    <template
      #stepper
    >
      <stepper
        :step-title="stepTitle"
        :in-step="inStep"
        :steps-name="stepsName"
        @stepChange="stepChange"
      /></template>
    <template #mainTitle>
      <v-toolbar-title class="light-blue--text text--darken-4 font-weight-black text-md-h5">
        <span>GNSS高精度定位</span>

      </v-toolbar-title>
      <v-spacer />
      <v-btn :disabled="inStep === 1" rounded class="mr-2 " @click="previousStep">上一步</v-btn>
      <v-btn :disabled="inStep === stepsName.length" rounded @click="nextStep">下一步</v-btn>
    </template>
    <template #main>

      <step-card v-show="inStep === 1">
        <template #title>
          在本章节需要用到
        </template>
        <template #introductionText>
          <p>
            一个移动热点（没有热点设备，可以用手机开热点来代替）
          </p>
          <p>
            一个装有“北斗智能终端”app的安卓手机
          </p>
          <p>
            一个北斗ROS智能终端（以下简称为“终端“）
          </p>
          <p>
            一个蘑菇头天线
          </p>
          <p>
            手动将蘑菇头天线与终端连接
          </p>
        </template>
      </step-card>
      <step-card v-show="inStep === 2">
        <template #title>
          网络配置
        </template>
        <template #introductionText>
          <P>打开热点，将终端和安卓手机连入，保证两者在同一个局域网内可以通信</P>
          <P class="font-weight-black black--text text-xl-h5">使用终端连接WiFi</P>
          <v-form
            ref="wifiForm"
            v-model="WifiValid"
            lazy-validation
          >
            <v-text-field
              v-model="wiFiName"
              :counter="40"
              :rules="nameRules"
              label="输入wifi账号"
              required
            />
            <v-text-field
              v-model="WifiPassword"
              :rules="passwordRules"
              :append-icon="WifiShow ? 'mdi-eye' : 'mdi-eye-off'"
              :type="WifiShow ? 'text' : 'password'"
              :counter="40"
              label="请输入密码"
              required
              @click:append="WifiShow = !WifiShow"
            />
            <v-btn
              :disabled="!WifiValid"
              color="success"
              class="mr-4"
              @click="validateWifi"
            >
              连接WiFi
            </v-btn>
          </v-form>
        </template>
      </step-card>
      <step-card v-show="inStep === 3">
        <template #title>
          设置参数
        </template>
        <template #introductionText>
          填写网络RTK账号
          <v-form
            ref="rtkForm"
            v-model="RTKValid"
            lazy-validation
          >
            <v-text-field
              v-model="RTKName"
              :counter="40"
              :rules="nameRules"
              label="输入RTK账号"
              required
            />
            <v-text-field
              v-model="RTKPassword"
              :rules="passwordRules"
              :append-icon="RTKShow ? 'mdi-eye' : 'mdi-eye-off'"
              :type="RTKShow ? 'text' : 'password'"
              :counter="40"
              name="input-10-2"
              label="请输入密码"
              required
              @click:append="RTKShow = !RTKShow"
            />
            <v-btn
              :disabled="!RTKValid"
              color="success"
              class="mr-4"
              @click="validateTRK"
            >
              设置参数
            </v-btn>
          </v-form>
        </template>
      </step-card>
      <step-card v-show="inStep === 4">
        <template #title>
          开启定位模块
        </template>
        <template #introductionText>
          先点击开启定位模块按钮，然后在安卓手机“北斗智能终端”app中点击发现设备，如果网络配置正确，命令行界面会出现success字样
        </template>
        <template #btn>
          <v-btn color="primary" :href="rtk_startModule">开启定位模块
          </v-btn>
        </template>
      </step-card>
      <step-card v-show="inStep === 5">
        <template #title>
          开启网络RTK服务器
        </template>
        <template #introductionText>
          这个阶段下面命令行暂时没有输出，待完成下一步之后，将会有输出
        </template>
        <template #btn>
          <v-btn :href="rtk_startNetwork" color="primary">开启网络RTK服务器</v-btn></template>
      </step-card>
      <step-card v-show="inStep === 6">
        <template #title>
          开启网络RTK定位模式
        </template>
        <template #introductionText>
          <p>在安卓手机端的“北斗智能终端”查看定位结果</p>
          <p>正确开启后，下面命令行会有定位的原始数据</p>
        </template>
        <template #btn>
          <v-btn color="primary" :href="rtk_startRTK">开启网络RTK定位模式</v-btn>
        </template>
      </step-card>
    </template>
  </normal-template>
</template>

<script>
import NormalTemplate from 'components/normaltemplate'
import stepper from 'components/stepper'
import StepCard from 'components/stepCard'
import { Base64 } from 'js-base64'
export default {
  components: { StepCard, NormalTemplate, stepper },
  data: () => ({

    wiFiName: '',
    WifiPassword: '',
    WifiShow: false,
    WifiValid: true,
    RTKShow: false,
    RTKPassword: '',
    RTKValid: true,
    RTKName: '',
    rtk_startModule: 'sbt://' + Base64.encode('rtk_startModule'),
    rtk_startNetwork: 'sbt://' + Base64.encode('rtk_startNetwork'),
    rtk_startRTK: 'sbt://' + Base64.encode('rtk_startRTK'),
    nameRules: [
      v => !!v || '账号不能为空',
      v => (v && v.length <= 40) || '账号必须小于40个字符'
    ],
    passwordRules: [
      v => !!v || '密码不能为空',
      v => (v && v.length <= 40) || '密码必须小于40个字符'
    ],
    items: [
      'Item 1',
      'Item 2',
      'Item 3',
      'Item 4'
    ],

    stepTitle: 'GNSS高精度定位',
    inStep: 1,
    stepsName: ['准备阶段', '网络配置', '设置参数', '开启定位模块', '开启网络RTK服务器', '开启RTK定位模式']
  }),
  watch: {
    inStep(val) {
      // console.log(val)
    }
  },
  methods: {
    stepChange(val) {
      this.inStep = val
    },
    nextStep() {
      if (this.inStep < this.stepsName.length) {
        this.inStep++
      }
    },
    previousStep() {
      if (this.inStep > 1) {
        this.inStep--
      }
    },
    validateTRK() {
      if (this.$refs.rtkForm.validate()) {
        this.submitRTK()
      }
    },
    submitRTK() {
      const submitRTK = Base64.encode('rtk_set_argument' + '%20' + this.RTKName + '%20' + this.RTKPassword)
      console.log(submitRTK)
      console.log(Base64.decode(submitRTK))
      window.location.href = 'sbt://' + submitRTK
    },
    validateWifi() {
      if (this.$refs.wifiForm.validate()) {
        this.submitWifi()
      }
    },
    submitWifi() {
      console.log('sbt://' + Base64.encode('rtk_set_wifi%20' + '%20' + this.wiFiName + '%20' + this.WifiPassword))
      window.location.href = 'sbt://' + Base64.encode('rtk_set_wifi%20' + '%20' + this.wiFiName + '%20' + this.WifiPassword)
    }
  }
}

</script>

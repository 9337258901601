import gnss from 'views/gnss/index.vue'
import moveToGrab from 'views/moveToGrab/index'
import targetDetection from 'views/targetDetection/index'
import robotNavigationPlanning from 'views/robotNavigationPlanning/index'
import rosInstall from 'views/rosInstall/index'
const routes = [

  {
    path: '/gnss',
    name: 'gnss',
    component: gnss,
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      roles: ['admin', 'teacher', 'student']
    }
  },
  {
    path: '/moveToGrab',
    name: 'moveToGrab',
    component: moveToGrab,
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      roles: ['admin', 'teacher', 'student']
    }
  },
  {
    path: '/targetDetection',
    name: 'targetDetection',
    component: targetDetection,
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      roles: ['admin', 'teacher', 'student']
    }
  },
  {
    path: '/robotNavigationPlanning',
    name: 'robotNavigationPlanning',
    component: robotNavigationPlanning,
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      roles: ['admin', 'teacher', 'student']
    }
  },
  {
    path: '/rosInstall',
    name: 'rosInstall',
    component: rosInstall,
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      roles: ['admin', 'teacher', 'student']
    }
  }]
export default {
  namespaced: true,
  routes
}
